import React, { useEffect, useState } from "react"
import Slide from "../components/Slide"
import { Link, StaticQuery, graphql, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Jumbo from "../components/Jumbo"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Layout from "../components/layout.js"

import ElectricCarSvg from "../assets/svg/electric_car.svg"
import TreesSvg from "../assets/svg/trees.svg"
import SavingsSvg from "../assets/svg/savings.svg"
import SolarPanelSvg from "../assets/svg/solar_panel.svg"
import SolarPanel2Svg from "../assets/svg/solarpanel2.svg"
import PointerSvg from "../assets/svg/pointer.svg"
import HeartbeatSvg from "../assets/svg/heartbeat.svg"
import FutureSvg from "../assets/svg/future.svg"
import RateHikesSvg from "../assets/svg/rate-hikes.svg"
import OutageSvg from "../assets/svg/outage.svg"
import SustainabilitySvg from "../assets/svg/sustainability.svg"
import PathlineSvg from "../assets/svg/pathline2.svg"
import ToolsSvg from "../assets/svg/tools.svg"
import HelmetSvg from "../assets/svg/helmet.svg"
import BillSvg from "../assets/svg/bill.svg"
import AgreementSvg from "../assets/svg/agreement.svg"

import Speedometer from "../components/Speedometer"

import Animated from "../components/Animated"
import useVisibility from "../components/SetVisibility"

import Modal from "../components/Modal"

export const Head = () => (
  <>
    <title>Instalacion paneles solares residencial, casa, hogar - Chile</title>
    <meta
      name="description"
      content="Instalación de paneles solares en casas con diseños especializados para un mayor rendimiento y larga vida útil"
    />
    <meta
      name="keywords"
      content="Paneles solares casa, residencial chile, casa solar, energía solar residencial, energia solar hogar, paneles fotovoltaicos Chile"
    />
  </>
)

const Residencial = props => {
  const design_figure = React.useRef()
  const ahorro_list = React.useRef()
  const vanguardia_list = React.useRef()
  const squiggle = React.useRef()

  const isDesignVisible = useVisibility(design_figure)
  const isAhorroVisible = useVisibility(ahorro_list)
  const isVanguardiaVisible = useVisibility(vanguardia_list)
  const isSquiggleVisible = useVisibility(squiggle)

  const click = e => {
    e.preventDefault()
    navigate("/miplanta?tipo=residencial")
  }

  const android = getImage(props.android)
  const girl = getImage(props.girl)
  const design = getImage(props.design)
  const panels = getImage(props.panels)
  const savings = getImage(props.savings)
  const independencia = getImage(props.independencia)
  const mundoelectrico = getImage(props.mundoelectrico)
  const instalacion = getImage(props.instalacion)
  const visitatecnica = getImage(props.visitatecnica)
  const regularizacion = getImage(props.regularizacion)
  const reserva = getImage(props.reserva)

  return (
    <Layout>
      <NavBar {...props} />

      <Jumbo jumbo={props.jumbo} alt="Placas solares para casas">
        <h1>Hogar Solar</h1>
        <h2>Energía Limpia</h2>
        <div className="row mt-auto justify-content-md-center position-relative mx-3">
          <div className="offset-lg-1 col col-lg-2 col-md-3 col-sm-4">
            <div className="feature_content">
              <SavingsSvg />
              <h5>Ahorro Rentable</h5>
            </div>
          </div>
          <div className="col col-lg-2 col-md-3 col-sm-4">
            <div className="feature_content">
              <SolarPanelSvg />
              <h5>Independencia Energética</h5>
            </div>
          </div>
          <div className="col col-lg-2 col-md-3 col-sm-4">
            <div className="feature_content">
              <TreesSvg />
              <h5>Cuidado Ambiental</h5>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 d-none d-md-block">
            <div className="feature_content">
              <ElectricCarSvg />
              <h5>Vanguardia a un Mundo Eléctrico</h5>
            </div>
          </div>
          <Link
            to="/miplanta"
            className="btn btn-primary col-lg-2 col-sm-4 mx-auto ms-lg-1 mt-3 my-md-auto"
          >
            Diseño Personalizado
          </Link>
        </div>
      </Jumbo>

      <section className="my-5">
        <div className="ms-3">
          <h4>Tus paneles solares</h4>
          <h3>En pocos pasos</h3>
        </div>

        <div
          className={`mt-5 mx-auto curved-timeline ${
            isSquiggleVisible ? "is-visible" : ""
          }`}
          ref={squiggle}
        >
          <div className="curved-timeline-step">
            <BillSvg
              className="textup image"
              style={{ width: "100px", height: "100px" }}
            />
            <h5>
              <span>1</span>
              Nos envías tus boletas
            </h5>
          </div>
          <div className="curved-timeline-step">
            <HelmetSvg
              className="image"
              style={{ width: "100px", height: "100px" }}
            />
            <h5>
              <span>2</span>
              Visita técnica y elaboración de propuesta
            </h5>
          </div>
          <div className="curved-timeline-step">
            <ToolsSvg
              className="textup image"
              style={{ width: "100px", height: "100px" }}
            />
            <h5>
              <span>3</span>
              Instalación y puesta en marcha
            </h5>
          </div>
          <div className="curved-timeline-step">
            <AgreementSvg
              className="textup image"
              style={{ width: "100px", height: "100px" }}
            />
            <h5>
              <span>4</span>
              Regularización
            </h5>
          </div>
          <div className="curved-timeline-step">
            <SolarPanel2Svg
              className="textup image"
              style={{ width: "100px", height: "100px" }}
            />
            <h5>
              <span>5</span>
              Conexión a la red
            </h5>
          </div>
          <PathlineSvg
            id="curved-timeline"
            className={`${isSquiggleVisible ? "is-visible" : ""}`}
          />
        </div>
      </section>

      <section className="section-large my-5">
        <div className="row justify-content-md-center">
          <div className="col-md-4 col-sm-12 my-auto me-1">
            <div className="ms-3">
              <h4>Ahorro</h4>
              <h3>Rentable</h3>
            </div>
            <ul className="list-group list-group-flush" ref={ahorro_list}>
              <Animated
                animationIn="slideInLeft"
                animationOut="fadeOut"
                isVisible={isAhorroVisible}
              >
                <li className="list-group-item">Energía gratis</li>
              </Animated>
              <Animated
                animationIn="slideInLeft"
                animationInDelay={300}
                animationOut="fadeOut"
                isVisible={isAhorroVisible}
              >
                <li className="list-group-item">Larga vida útil</li>
              </Animated>
              <Animated
                animationIn="slideInLeft"
                animationInDelay={600}
                animationOut="fadeOut"
                isVisible={isAhorroVisible}
              >
                <li className="list-group-item">
                  Mayor plusvalía de la propiedad
                </li>
              </Animated>
              <Animated
                animationIn="slideInLeft"
                animationInDelay={900}
                animationOut="fadeOut"
                isVisible={isAhorroVisible}
              >
                <li className="list-group-item">
                  Rápido retorno de la inversión
                </li>
              </Animated>
            </ul>

            <Animated
              animationIn="slideInLeft"
              animationInDelay={1200}
              animationOut="fadeOut"
              isVisible={isAhorroVisible}
            >
              <Link to="/contact" className="mt-3 btn btn-primary w-100">
                Quiero saber más
              </Link>
            </Animated>
          </div>
          <div className="col-md-7 col-sm-12">
            <GatsbyImage
              image={savings}
              imgStyle={{ paddingTop: "2rem", paddingBottom: "2rem" }}
              alt="Ahorro rentable con paneles solares"
            />
          </div>
        </div>
      </section>

      <section className="section-large my-5">
        <div className="row justify-content-md-center">
          <div className="col-lg-8 col-sm-12">
            <div className="pointer-container" ref={design_figure}>
              <GatsbyImage
                image={design}
                alt=" Ingeniería y diseño de proyectos paneles solares"
              />
              <div
                className={`pointer1 ${isDesignVisible ? "is-visible" : ""}`}
              >
                <PointerSvg />
                <span className="pointer-text">Personalizado</span>
              </div>
              <div
                className={`pointer2 ${isDesignVisible ? "is-visible" : ""}`}
              >
                <PointerSvg />
                <span className="pointer-text">Armonioso</span>
              </div>
              <Speedometer />
            </div>
          </div>
          <div className="col-lg-3 col-xs-12 px-5 px-lg-0">
            <div className="row">
              <div className="col-lg-12 col-md-6 col-xs-12 pt-4 px-3">
                <h4>Diseño de</h4>
                <h3>Mayor rendimiento</h3>
                <Link to="/miplanta" className="btn btn-primary w-100 mt-4">
                  Diseño Personalizado
                </Link>
              </div>
              <div className="col-lg-12 col-md-6 col-xs-12 my-auto pt-4 px-5">
                Diseños personalizados a tus necesidades para un mejor
                rendimiento, estético y armonioso en tu hogar.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-banner my-5 position-relative">
        <GatsbyImage
          image={panels}
          style={{ height: "100%", opacity: 0.6 }}
          alt="Placas solares para el hogar"
        />
        <div
          className="mt-3 ms-3"
          style={{ color: "white", position: "absolute", top: 0 }}
        >
          <h4>Cuidado del</h4>
          <h3>Medio Ambiente</h3>
          Por un futuro limpio, libre de emisiones de CO2
        </div>
        <div
          className="row justify-content-md-center position-absolute w-100 mx-auto"
          style={{ bottom: "1rem" }}
        >
          <div className="col-lg-2 col-md-3 col-4">
            <div className="feature_content">
              <TreesSvg />
              <h5>Cuidado</h5>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-4">
            <div className="feature_content">
              <HeartbeatSvg />
              <h5>Salud</h5>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-4">
            <div className="feature_content">
              <FutureSvg />
              <h5>Futuro</h5>
            </div>
          </div>
        </div>
      </section>

      {/*<div className="row mt-auto justify-content-md-center">
          <div className="col-lg-2 col-md-3 col-4">
            <h4>Ahorro</h4>
            <h3>Rentable</h3>
            <Link to="/miplanta" className="btn btn-primary w-100">
              Diseño Personalizado
            </Link>
          </div>
          <div className="col-lg-2 col-md-3 col-4">
            reduce la cuenta, aumenta el valor de la propiedad, mantenimiento
            minimo, costo creciente de la electricidad
          </div>
        </div>*/}

      <section className="section-large my-5">
        <div className="row justify-content-md-center">
          <div className="col-md-7 col-sm-12">
            <GatsbyImage
              image={mundoelectrico}
              imgStyle={{ paddingTop: "2rem", paddingBottom: "2rem" }}
              alt="Electromovilidad, IoT y teconología paneles solares"
            />
          </div>
          <div className="col-md-4 col-sm-12 my-auto me-1">
            <div className="ms-3">
              <h4>Vanguardia a un</h4>
              <h3>Mundo Eléctrico</h3>
            </div>
            <ul className="list-group list-group-flush" ref={vanguardia_list}>
              <Animated
                animationIn="slideInRight"
                animationOut="fadeOut"
                isVisible={isVanguardiaVisible}
              >
                <li className="list-group-item">Electromovilidad</li>
              </Animated>
              <Animated
                animationIn="slideInRight"
                animationInDelay={300}
                animationOut="fadeOut"
                isVisible={isVanguardiaVisible}
              >
                <li className="list-group-item">Internet de las cosas</li>
              </Animated>
              <Animated
                animationIn="slideInRight"
                animationInDelay={600}
                animationOut="fadeOut"
                isVisible={isVanguardiaVisible}
              >
                <li className="list-group-item">
                  Monitoreo de paneles en tiempo real
                </li>
              </Animated>
              <Animated
                animationIn="slideInRight"
                animationInDelay={900}
                animationOut="fadeOut"
                isVisible={isVanguardiaVisible}
              >
                <li className="list-group-item">
                  Domótica y hogares inteligentes
                </li>
              </Animated>
            </ul>
            <Animated
              animationIn="slideInRight"
              animationInDelay={1200}
              animationOut="fadeOut"
              isVisible={isVanguardiaVisible}
              className="mx-3"
            >
              <Link to="/contact" className="mt-3 btn btn-primary w-100">
                Quiero saber más
              </Link>
            </Animated>
          </div>
        </div>
      </section>

      <section className="section-banner my-5 position-relative">
        <GatsbyImage
          image={independencia}
          style={{ height: "100%", opacity: 0.6 }}
          alt="Independencia energética con paneles fotovoltaicos"
        />
        <div
          className="col-6 offset-6 col-md-4 offset-md-8 mt-3 position-absolute"
          style={{ color: "white", top: 0 }}
        >
          <h4>Independencia</h4>
          <h3>Energética</h3>
          Protección de electrodomésticos por cortes, asegura el suministro,
          confianza, costo creciente de la electricidad
        </div>
        <div
          className="row justify-content-md-center position-absolute w-100 mx-auto"
          style={{ bottom: "1rem" }}
        >
          <div className="col-4 col-lg-2 col-md-3">
            <div className="feature_content">
              <OutageSvg />
              <h5>Protección de electrodomésticos ante cortes</h5>
            </div>
          </div>
          <div className="col-4 col-lg-2 col-md-3">
            <div className="feature_content">
              <SustainabilitySvg />
              <h5>Suministro eléctrico confiable</h5>
            </div>
          </div>
          <div className="col-4 col-lg-2 col-md-3">
            <div className="feature_content">
              <RateHikesSvg />
              <h5>Protección ante alzas de precios</h5>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  )
}

function Page(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          jumbo: file(relativePath: { eq: "residencial.jpg" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          android: file(relativePath: { regex: "/android.jpg/" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          girl: file(relativePath: { regex: "/girl.jpg/" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          design: file(relativePath: { regex: "/architectural_panels.jpg/" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          panels: file(relativePath: { regex: "/green_horizon.jpg/" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          savings: file(relativePath: { regex: "/savings.jpg/" }) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          mundoelectrico: file(
            relativePath: { regex: "/web-panel6-min.jpg/" }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          independencia: file(
            relativePath: { regex: "/web-paneles10-min.jpg/" }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      `}
      render={data => (
        <Residencial
          android={data.android}
          girl={data.girl}
          jumbo={data.jumbo}
          design={data.design}
          panels={data.panels}
          savings={data.savings}
          independencia={data.independencia}
          mundoelectrico={data.mundoelectrico}
          {...props}
        />
      )}
    />
  )
}
export default Page
