import React, { useEffect, useState } from "react"
import GaugeNeedleSvg from "../../assets/svg/svg-meter-gauge-needle.svg"
import SpeedometerSvg from "../../assets/svg/speedometer.svg"

import "./speedometer.scss"

const Speedometer = props => {
  const [isVisible, setVisible] = React.useState(true)
  const [value, setValue] = React.useState(true)
  const meter_ref = React.useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting))
    })
    observer.observe(meter_ref.current)
  })

  useEffect(() => {
    const speed = 10
    function increment() {
      setValue(value + 2)
    }
    if (isVisible) {
      if (value < 100) {
        setTimeout(function () {
          increment()
        }, speed)
      }
    } else {
      setValue(0)
    }
  }, [isVisible, value])

  return (
    <div id="wrapper" ref={meter_ref}>
      <SpeedometerSvg
        id="meter"
        className={`${isVisible ? "is-visible" : ""}`}
      />
      <GaugeNeedleSvg
        id="meter_needle"
        className={`${isVisible ? "is-visible" : ""}`}
      />
      <span>{value}kWh</span>
    </div>
  )
}

export default Speedometer
