import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import "./modal.scss"

export default class Modal extends React.Component {
  render() {
    return (
      <div
        className="modal fade"
        id={this.props.id}
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="myModalLabel">
                {this.props.title}
              </h3>
            </div>
            <div className="modal-body">{this.props.children}</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
